@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'), url(fonts/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Robotos'), local('Roboto'), url(fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Robotos Italic'), local('Roboto-Italic'), url(fonts/Roboto/Roboto-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    src: local('Roboto Semibold'), local('Roboto-Semibold'), url(fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    src: local('Roboto Semibold Italic'), local('Roboto-SemiboldItalic'), url(fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    src: local('Roboto Extrabold'), local('Roboto-Extrabold'), url(fonts/Roboto/Roboto-Black.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 800;
    src: local('Roboto Extrabold Italic'), local('Roboto-ExtraboldItalic'), url(fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
}



@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url(fonts/Open_Sans/OpenSans-LightItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(fonts/Open_Sans/OpenSans-Italic.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(fonts/Open_Sans/OpenSans-BoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'), url(fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(fonts/Open_Sans/OpenSans-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url(fonts/Open_Sans/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'), url(fonts/Open_Sans/OpenSans-ExtraBold.ttf) format('truetype');
}
