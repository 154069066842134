* {
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100%;
  position: relative;
  color: #333333;
  font-family: Roboto,sans-serif;
  font-size: 13px;
}

.icon-arrow-right {
  &:before, &:after {
    content: "";
    height: 7px;
    width: 1px;
    position: absolute;
    background-color: #333333;
    left: 10px;
  }

  &:before {
    transform: rotate(45deg);
    top: 9px;
  }

  &:after {
    transform: rotate(-45deg);
    top: 5px;
  }
}

.font-weight-300 {
  font-weight: 300;
}

.ug-top-header {
  background-color: #033d58;
  padding: 10px 20px;
  color: #ffffff;
  height: 50px;

  .ug-logo-wrapper {
    display: inline-block;
    img {
      vertical-align: text-bottom;
    }
  }

  .version-details {
    font-size: 16px;
    padding-left: 15px;
    display: inline-block;
    vertical-align: bottom;
    //line-height: 1.31;

    .version-name {
      font-weight: 500;
      color: #fff;
    }
  }
  .version-number {
      font-weight: 500;
      color: #fff;
      margin-left: 10px;
      font-size: 13px;
      position: relative;
      top: -2px;
    }
}

.navigation {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 35px;

  .navigation-wrapper {
    height: 100%;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: #333;
  }
  .navigation-wrapper {
    background: #ffffff;
    border-bottom: 1px solid #e1e1e1;
    .navigation-item {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      &:hover {
        & > .nav-link {
          background: #033d58;
          color: #fff;
          &:after {
            border-color: #ffffff transparent transparent transparent;
          }
        }
        .sub-navigation-wrapper {
          opacity: 1;
          visibility: visible;
          z-index: 10;
        }
      }
      & > .nav-link {
        display: inline-block;
        position: relative;
        padding: 7px 33px 7px 15px;
        transition: background .3s;
        &:after {
          content: "";
          display: block;
          position: absolute;
          border: 5px solid;
          border-color: #10639b transparent transparent transparent;
          right: 15px;
          top: 15px;
        }
        &.active {
          background: #033d58;
          color: #fff;
          &:after {
            border-color: #ffffff transparent transparent transparent;
          }
        }
      }


      .sub-navigation-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 173px;
        padding: 5px 0;
        border: 1px solid #e1e1e1;
        background: #fff;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity .3s,visibility .3s;
        -o-transition: opacity .3s,visibility .3s;
        transition: opacity .3s,visibility .3s;
        .sub-navigation-item {
          & > a {
            display: block;
            padding: 9px 15px;
            &:hover {
              color: #10639b;
            }
          }
        }
      }
    }
  }
}
.dcp-body {
  padding: 20px 20px 3px;
  .banner-area {
    background: url('../images/sectionIntro.png') no-repeat;
    height: 150px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 20px;
    background-size: cover;
    .banner-icon {
      background: url('../images/banner-icon.svg');
      background-repeat: no-repeat;
      height: 122px;
      width: 122px;
    }
    .banner-body {
      img {
        float: left;
      }

      h1 {
        padding-left: 20px;
        color: #fff;
        font-weight: 300;
        font-size: 28px;
        display: inline-block;
        position: relative;
        top: 22px;
        margin: 0;
      }
    }
  }
  .categories-container {
    padding: 10px 33px;
    .row {
      margin: 0 -10px;
    }
    .category-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 10px;
      min-height: 185px;
      a {
        position: relative;
        width: 100%;
        height: 100%;
        background: transparent;
        padding: 15px;
        border-color: #9D559B;
        border-style: solid;
        border-width: 4px 0 0 4px;
        -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2);
        box-shadow: 0 1px 4px rgba(0,0,0,.2);
        font-size: 13px;
        line-height: 1.85;
        color: #333;
        text-decoration: none;
        h2 {
          position: relative;
          margin-bottom: 13px;
          font-size: 22px;
          font-weight: 500;
        }
      }
    }
  }
}

.most-popular-topics-container {
  .box {
    padding: 20px 20px 8px;
    border: 1px solid #e1e1e1;
    font-size: 13px;
    line-height: 1.85;
    .row {
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px;
      & > .col-md-6, & > .col-lg-3 {
        padding: 0 10px;
      }

      h2 {
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 24px;
        margin-top: 0;
        color: #10639b;
      }
      ul, li {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .list-links {
        column-count: 2;
        max-width: 612px;
        li {
          padding-right: 30px;
          margin-bottom: 10px;
          a {
            text-decoration: none;
            color: #10639b;
            -webkit-transition: color .3s;
            -o-transition: color .3s;
            transition: color .3s;
            font-family: Open Sans,sans-serif;
            &:hover {
              color: #333;
            }
          }
        }
      }
    }
  }
}

footer {
  padding: 2px 20px 4px;
  background: #efefef;
  box-shadow: 0 -1px 0 #e1e1e1;
  ul, li {
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
    justify-content: flex-end;
  }
  li {
    list-style: none;
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-size: 11px;
    line-height: 1;
    color: #333;
    padding: 5px 7px;
    & + li:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 1px;
      height: 11px;
      background: #999;
  }
    a {
      color: #333;
    }
    &.copyright {
      color: #999;
    }
  }
}


.ug-body {
  display: flex;
  min-height: calc(100vh - 50px);
  height: 100%;
  width: 100%;
  background-color: #ffffff;

  .link {
    color: #10639B;
    font-family: "Open Sans", sans-serif;

    &:hover {
      color: #333333;
    }
  }
}

.ug-left-container {
  flex: auto;
  border-right: 1px solid #e1e1e1;
  padding: 20px 0 30px;
  position: sticky;
  top: 35px;
  height: calc(100vh - 35px);
  overflow: overlay;

  .ug-nav-header {
    padding: 0 0 20px 20px;
    font-size: 18px;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
    cursor: pointer;

    &.menu-item {
      &.active > span {
        background-color: #10639B;
        color: #ffffff;
      }

      span {
        display: block;
        padding: 17px 45px 17px 20px;
        font-size: 13px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        border-bottom: 1px solid #e1e1e1;
        position: relative;

        i.icon-arrow-right {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          transition: ease all .2s;
          height: 20px;
          width: 20px;
        }

        p {
          margin: 0;
          padding: 0;

          img {
            display: inline-block;
          }
        }
      }

      &.is-open > span i.icon-arrow-right {
        transform: translateY(-50%) rotate(90deg);
      }

      &.active > span i.icon-arrow-right {
        &:before, &:after {
          background-color: #ffffff;
        }
      }

      ul {
        position: relative;
        transform: scaleY(0);
        height: auto;
        max-height: 0;
        transform-origin: top;
        margin-left: 10px;
        transition: ease all .3s;

        &.open {
          transform: scaleY(1);
        }

        .sub-menu-item {
          font-size: 13px;
          font-weight: 700;
          color: #10639B;
        }

        .menu-item {
          font-size: 13px;
          font-weight: 700;
          color: #10639B;

          .icon-arrow-right {
            &:before, &:after {
              background-color: #10639B;
            }
          }
        }

        .sub-menu-item.active {
          background: #10639B;
          color: #ffffff;
        }

        .menu-item {
          .sub-menu-item span {
            padding: 17px 53px 17px 30px;
          }

          &.active > span {
            background: #10639B;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.ug-right-container {
  flex: auto;
  padding: 20px 20px 30px 48px;

  .ug-breadcrumb {
    padding: 5px 0 20px 0;
    position: sticky;
    top: 35px;
    background-color: #ffffff;
    z-index: 1;

    .link {
      position: relative;
      padding: 5px 12px 5px 0px;
      margin-right: 5px;
      cursor: pointer;
      text-decoration: none;

      &:after {
        content: '>';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        line-height: 1;
        color: #333;
      }
    }
  }

  .ug-content-wrapper {
    font-size: 13px;

    .ug-content-main-header {
      position: relative;
      width: calc(100% + 10px);
      padding: 25px 0 13px;
      border-bottom: 1px solid #d8d8d8;
      margin: 0 0 22px;
      font-weight: 400;
      font-size: 28px;

      &:after {
        content: "";
        position: absolute;
        bottom: -9px;
        left: 0;
        width: 104px;
        height: 8px;
        background: #9D559B;
      }
    }

    .ug-content {
      font-family: "Open Sans", sans-serif;

      p {
        margin-bottom: 20px;
      }
    }

    ul {
      margin-bottom: 20px;
      list-style: none;
      padding: 0;
    }

    ol {
      > li {
        padding-left: 15px;

        &:before {
          content: none;
        }
      }

      &.lower-alpha {
        list-style-type: lower-alpha;
      }
    }

    li {
      margin: 10px 0;
      padding-left: 38px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 13px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #333333;
      }

      img {
        vertical-align: top;
        position: relative;
        //top: -10px;
      }

      p {
        margin-top: 20px;
      }
    }

    figure {
      display: block;
      margin: 50px 0;
      max-width: 100%;

      img {
        width: auto;
        max-width: 100%;
        border: 1px solid #d8d8d8;
      }

      figcaption {
        margin-top: 10px;
        font-size: 11px;
      }
    }

    table {
      margin: 20px 0 45px;
      table-layout: fixed;

      th {
        background: linear-gradient(180deg, #e0dcdb 0, #f6f6f6);
        height: 32px;
        font-weight: 600;
        border: 1px solid #d8d8d8;
        padding: .75rem;
        line-height: unset;
        vertical-align: top;
        color: #333333;
      }

      td {
        height: 37px;
        border-bottom: 1px solid #eaeaea;
        padding: .75rem;
        vertical-align: top;
      }
    }
  }
}
