.adpt-body {
  padding-top: 60px;
}

.row {
  margin-right: 0 !important;
}

.side-bar {
  border-right: 1px solid #e5e5e5;
  padding-right: 0 !important;
  height: 100vh;
  position: fixed !important;

  .title {
    font: normal normal bold 18px/21px Roboto;
    border-bottom: none !important;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }

  div {
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    font-style: 12px !important;
    font-weight: 400 !important;
    padding: 26px 20px;
  }
}

.adpt-type:hover:not(.selected) {
  cursor: pointer;
  background: #f5f5f5;
  color: #333333;
}

.adapter-container {
  position: relative;
  min-height: 100vh;

  .search-bar {
    width: 100%;
    background: #fff;
    position: sticky;
    z-index: 1;
    padding-bottom: 15px;
    top: 0;
    margin-left: -5px;
  }

  .col-md-3 {
    padding: 10px;

    .cards {
      border: 1px solid #e5e5e5;
      height: 130px;
      padding: 10px;
      border-radius: 3px;
    }
  }

  .col-md-4 .cards {
    border: 1px solid #e5e5e5;
    height: 130px;
    padding: 10px;
    border-radius: 3px;
  }
}

.adapters-card__name {
  height: 27px;
  text-align: center;
  font-family: "Open Sans",sans-serif !important;
  font-size: 13px;
  color: #212121;
  font-weight: normal;
}

.adapters-card__name--text {
  display: inline-block;
  max-width: 60%;
}

.adapters-card__name--version {
  display: inline-block;
  max-width: 60%;
  max-width: 20%;
}

.adapters-card__name--divider {
  display: inline-block;
  border-right: 1px solid #AFB2AA;
  height: 15px;
  margin: 0 5px 0 4px;
  position: relative;
  top: 3px;
}

.adapters-card__logo {
  height: 65px;
  text-align: center;
  position: relative;

  img {
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 35px);
    width: 70px;
    height: 35px;

    &[src*='adapters.svg'] {
      top: calc(50% - 12px);
      height: 25px;
    }
  }
}

input[type=text] {
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  width: 0px;
  box-sizing: border-box;
  background-color: white;
  background-image: url('../images/search.svg');
  background-size: 24px;
  background-position: 0px 9px;
  background-repeat: no-repeat;
  padding: 12px 20px 5px 25px;
  -webkit-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
  font: normal normal normal 12px/17px 'Open Sans';
  color: #212121;

  &::-ms-clear {
    display: none;
  }
}

.visibale-txt {
  width: 280px !important;
  border-bottom-style: groove !important;
  border-bottom: 1px solid #ccc;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}

input[type=text] {
  &:hover::-webkit-input-placeholder, &:focus::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #949494;
    font: normal normal normal 12px/17px 'Open Sans';
    padding-left: 3px;
  }

  &:hover::-moz-placeholder, &:focus::-moz-placeholder {
    /* Firefox 19+ */
    color: #949494;
    font: normal normal normal 12px/17px 'Open Sans';
    padding-left: 3px;
  }

  &:hover:-ms-input-placeholder, &:focus:-ms-input-placeholder {
    /* IE 10+ */
    color: #949494;
    font: normal normal normal 12px/17px 'Open Sans';
    padding-left: 3px;
  }

  &:hover:-moz-placeholder, &:focus:-moz-placeholder {
    /* Firefox 18- */
    color: #949494;
    font: normal normal normal 12px/17px 'Open Sans';
    padding-left: 3px;
  }
}

.close-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  /* color: #949494; */
  width: 12px;
  height: 12px;
  left: 270px;
}

.empty-state {
  display: none;
  width: 284px;
  height: 290px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);

  /* Chrome, Safari, Opera */

  img {
    width: 30px;
    height: 30px;
  }
}

.img-container {
  background-color: #e5e5e5;
  opacity: .5;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 55px;
  display: inline-block;
  margin-bottom: 10px;
}

.empty-state {
  .first-msg {
    color: #212121 !important;
    margin: 0 0 5px 0 !important;
    font-weight: 300 !important;
    font-size: 14px !important;
  }

  .second-msg {
    color: #212121 !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    margin: 0 0 20px 0;
  }
}