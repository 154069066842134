
.body-wrapper {
  height: calc(100vh - 65px);
  overflow: auto;

  .details {
    padding: 20px 18px 10px;
    max-width: 1460px;
    margin: 0 auto;

    .base-url-container {
      font-weight: 600;
    }

    .description {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .swagger-ui {
    .auth-wrapper {
      display: block;
      position: relative;
      top: 7px;

      .host-container {
        position: relative;
        float: left;

        label {
          position: absolute;
          top: -16px;
          left: 0px;
        }

        input {
          font-size: 14px;
          font-weight: 700;
          padding: 5px 40px 5px 10px;
          border: 1px solid #d8dde7;
          border-radius: 4px;
        }
      }

      .authorize.btn {
        float: right;
        position: relative;
        top: -5px;
      }
    }

    .information-container {
      display: none;
    }

    .scheme-container {
      box-shadow: none;
      margin: 0;
    }

    select {
      border: 1px solid #d8dde7;
      box-shadow: none;
      background-color: #fff;
    }

    .filter .operation-filter-input {
      border: 1px solid #d8dde7;
    }

    .opblock-tag {
      text-transform: capitalize;
    }
  }
}